import { Content } from 'components/Content';
import Footer from 'components/Footer/Footer';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';

import Invalid from '../images/404-invalid.png';

import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';

const Careers = () => {
	const [isMenuOpen, toggleMenu] = useState(false);
	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};
	return (
		<ThemeProviderComponent>
			<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
			<PaddingTopContainer isBurgerOpen={isMenuOpen}>
				<Content isOpen={isMenuOpen}>
					<Wrapper>
						<Info>
							<Error>Error: 404 - page not found</Error>
							<H1>We’re sorry - looks like this page is broken or cannot be found</H1>
							<ImgBoxMob>
								<Img src={Invalid} alt='404' />
							</ImgBoxMob>
							<ListWrapper>
								<ListHeading>Here are some useful links instead</ListHeading>
								<List>
									<Item>
										<Link to='/'>Homepage</Link>
									</Item>
									<Item>
										<Link to='/'>Patient Resources</Link>
									</Item>
									<Item>
										<Link to='/about'>About Us</Link>
									</Item>
									<Item>
										<Link to='/contact-us'>Contact Us</Link>
									</Item>
								</List>
							</ListWrapper>
						</Info>
						<ImgBox>
							<Img src={Invalid} alt='404' />
						</ImgBox>
					</Wrapper>
				</Content>
				<Footer />
			</PaddingTopContainer>
		</ThemeProviderComponent>
	);
};
const Wrapper = styled.div`
	margin: 60px 0 150px;
	display: flex;
	justify-content: space-between;
`;

const Info = styled.div`
	@media (max-width: 767px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
`;
const Error = styled.p`
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #4544f5;
`;
const H1 = styled.h1`
	font-family: 'SharpGrotesk-MediumNo21', sans-serif;
	margin-top: 22px;
	font-style: normal;
	font-weight: normal;
	font-size: 34px;
	line-height: 43px;
	color: #152231;
	max-width: 645px;
	@media (max-width: 767px) {
		font-size: 24px;
		line-height: 30.6px;
	}
`;

const ListWrapper = styled.div`
	margin-top: 89px;
	font-style: normal;
	font-weight: normal;
	font-size: 34px;
	line-height: 43px;
	color: #152231;
	display: flex;
	flex-direction: column;
	@media (max-width: 767px) {
		margin-top: 50px;
	}
`;
const ListHeading = styled.span`
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
`;
const List = styled.ul`
	list-style: none;
	margin-top: 24px;
`;
const Item = styled.li`
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 19px;
	text-decoration-line: underline;
	color: #4544f5;
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
	& > a {
		color: #4544f5;
	}
`;
const ImgBox = styled.div`
	display: none;
	@media (min-width: 768px) {
		display: block;
		width: 400px;
		height: 400px;
	}
`;
const ImgBoxMob = styled.div`
	display: none;
	@media (max-width: 767px) {
		display: block;
		text-align: center;
		width: 200px;
		height: 200px;
		margin-top: 50px;
	}
`;

const Img = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`;

export default Careers;
